<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav :selectedLanguage="selectedLanguage" page="blog3" />

    <div id="page" class="site grid-container container hfeed">
        <div id="content" class="site-content">
            <div id="primary" class="content-area">
                <main id="maincontent" class="site-main">
                    <section id="section-1" class="section-content">
                        <h1 class="section-1-blog-head">
                            Atualização empolgante: o GBWhatsApp introduziu o recurso de canais
                        </h1>

                        <!-- <div class="write-by">
                {{ $global.formatTimestamp(new Date()) }} por Amir Khan
              </div> -->

                        <p class="writter-content">
                            O <router-link :to="{name:`${selectedLanguage}-gb`}" class="jump-url">GBWhatsApp</router-link> apresentou um novo recurso emocionante - canais, semelhante ao Telegram. Ele permite que você compartilhe informações e se comunique de maneira organizada com seu público.
                            <br>
                            Agora, você pode aproveitar todos os benefícios dos canais na sua versão favorita do WhatsApp modificado. Vamos ver como os canais do WhatsApp podem transformar sua comunicação e ampliar o alcance do seu negócio.
                        </p>

                        <div class="lazy-picture-container writer-banner half-width">
                            <picture><img width="auto" height="auto" alt="gbwhatsapp channels" src="../assets/blog-cv-3.webp"></picture>
                        </div>

                        <h2 class="intro-title blog">
                            O que são os canais do GBWhatsApp?
                        </h2>

                        <p class="writter-content">
                            Se você usou o Telegram, rapidamente entenderá o conceito de canais no GBWhatsApp. Esses canais funcionam como ferramentas de comunicação unidirecional, onde os administradores transmitem mensagens para um grande público. Enquanto os seguidores podem reagir às postagens, não podem responder, garantindo que a comunicação seja eficiente e sem interrupções.
                            <br>
                            Os canais do GBWhatsApp permitem que os usuários se inscrevam em seus canais favoritos e recebam atualizações em vários formatos - texto, links, fotos ou vídeos. Essas atualizações são organizadas em uma seção dedicada dentro do aplicativo, distinta das conversas pessoais. Para acessá-las, basta ir à aba "Atualizações" (anteriormente chamada de "Status").
                        </p>

                        <h2 class="intro-title blog">
                            Como criar um canal no GBWhatsApp
                        </h2>

                        <p class="writter-content">
                            Para criar seu próprio canal no GBWhatsApp, certifique-se de que seu aplicativo esteja atualizado para a versão mais recente. Aqui está um guia passo a passo: <br>
                            1. Abra o GBWhatsApp e vá para a aba "Atualizações" onde os canais são exibidos.<br>
                            2. Toque no símbolo "+" e escolha "Criar canal".<br>
                        </p>
                        <div class="lazy-picture-container writer-banner half-width">
                            <picture><img width="auto" height="30%" alt="create channel" src="../assets/blog-3-pic-1.jpg"></picture>
                        </div>
                        <p class="writter-content">
                            3. Dê um nome ao seu canal (você pode alterá-lo mais tarde). <br>
                            4. Personalize seu canal adicionando uma descrição e um ícone, ou mantenha-os para depois. <br>
                            5. Uma vez que você tenha terminado, clique em "Criar canal". <br>
                            Parabéns, seu canal no GBWhatsApp está pronto!
                        </p>

                        <h2 class="intro-title blog">
                            Como se juntar a um canal no GBWhatsApp
                        </h2>

                        <p class="writter-content">
                            Juntar-se a um canal também é muito simples: <br>
                            1. Abra o GBWhatsApp e vá para a aba "Atualizações". <br>
                            2. Role para baixo e clique em "Buscar canais" ou "Ver mais" para explorar as opções disponíveis.<br>
                            3. Se você estiver procurando um canal específico, use a função de busca.<br>
                            4. Para se inscrever, basta clicar em "Seguir" no canal desejado.
                        </p>

                        <h2 class="intro-title blog">
                            Como compartilhar seu canal do GBWhatsApp
                        </h2>

                        <p class="writter-content">
                            Para compartilhar seu canal do GBWhatsApp com outras pessoas, siga estas etapas: <br>
                            1. Vá para a seção de canais e abra a página do canal que você deseja compartilhar.<br>
                            2. Toque no menu de três pontos no canto superior direito e escolha "Informações do canal".<br>
                            3. Use o botão "Compartilhar" para enviar o link pelo GBWhatsApp ou outros aplicativos, ou simplesmente copie o link para compartilhar onde quiser.
                        </p>

                        <h2 class="intro-title blog">
                            Limitações dos canais do GBWhatsApp
                        </h2>
                        <p class="writter-content">Embora os canais do GBWhatsApp abram novas oportunidades para transmitir mensagens, existem algumas limitações a serem consideradas se você planeja usá-los para negócios:<br>
                            <strong>- Segmentação limitada:</strong> Os canais não permitem o envio de mensagens pessoais ou direcionadas, então todos os membros recebem as mesmas atualizações.<br>
                            <strong>- Interação mínima:</strong> Os canais são principalmente para comunicação unidirecional. Os seguidores podem interagir com o conteúdo, mas não podem responder diretamente.<br>
                            <strong>- Visibilidade reduzida:</strong> Os usuários não recebem notificações push por padrão, o que significa que suas postagens podem ser ignoradas a menos que os membros verifiquem manualmente a aba de atualizações.<br>
                            <strong>- Nenhuma ferramenta de automação:</strong> Ao contrário de outras plataformas, os canais do GBWhatsApp não têm bots ou recursos de agendamento, tornando necessário o gerenciamento manual da comunicação.<br>
                            <strong>- Sem análises detalhadas:</strong> Atualmente, o GBWhatsApp não fornece informações sobre o desempenho das suas postagens, portanto, você não terá dados sobre as taxas de engajamento.<br>
                            <strong>- Sem criptografia de ponta a ponta:</strong> As mensagens enviadas através dos canais do GBWhatsApp não são criptografadas, então certifique-se de seguir as regras de dados se você estiver usando para negócios.<br>
                            Apesar dessas limitações, os canais do GBWhatsApp podem ser valiosos para empresas ou influenciadores que desejam expandir seu alcance e se comunicar de maneira eficaz com um público mais amplo.
                        </p>

                        <h2 class="intro-title blog">
                            O uso de canais do GBWhatsApp é gratuito?
                        </h2>

                        <p class="writter-content">
                            Sim, criar e usar canais no GBWhatsApp é atualmente gratuito, mesmo para empresas.
                        </p>

                        <h2 class="intro-title blog">
                            Perguntas Frequentes
                        </h2>

                        <p class="writter-content">
                            <strong>Pergunta: Os membros podem responder às postagens do meu canal do GBWhatsApp?</strong> <br>
                            Resposta: Não, os canais do GBWhatsApp são projetados apenas para comunicação unidirecional.
                        </p>

                        <p class="writter-content">
                            <strong>Pergunta: Como posso promover meu canal do GBWhatsApp?</strong> <br>
                            Resposta: Você pode compartilhar seu canal nas redes sociais ou incorporar seu link/código QR em seu site.
                        </p>

                        <p class="writter-content">
                            <strong>Pergunta: O uso de canais no GBWhatsApp é gratuito?</strong> <br>
                            Resposta: Sim, os canais estão atualmente disponíveis para criar e usar gratuitamente.
                        </p>

                        <p class="writter-content">
                            <strong>Pergunta: Posso compartilhar conteúdo de mídia no meu canal do GBWhatsApp?</strong> <br>
                            Resposta: Sim, você pode compartilhar texto, imagens e vídeos no canal.
                        </p>

                        <p class="writter-content">
                            <strong>Pergunta: Posso mudar o nome do meu canal do GBWhatsApp depois?</strong> <br>
                            Resposta: Sim, você pode mudar o nome do seu canal a qualquer momento.
                        </p>

                        <p class="writter-content">
                            <strong>Pergunta: Posso tornar meu canal do GBWhatsApp público para todos?</strong> <br>
                            Resposta: Sim, você pode tornar seu canal público ou privado.
                        </p>

                        <h2 class="intro-title blog">
                            Conclusão
                        </h2>

                        <p class="writter-content">
                            Os canais do GBWhatsApp são uma forma eficaz de comunicação, oferecendo uma nova maneira de se conectar com seu público e compartilhar mensagens. Embora existam limitações, esses canais são uma ferramenta excelente para compartilhar informações de forma eficiente. Esteja preparado para aproveitar este novo recurso e incorporá-lo em sua comunicação!
                        </p>
                    </section>
                </main>
            </div>
        </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
        <footer class="site-info">
            <div class="inside-site-info grid-container">
                <div class="footer-bar">
                    <a href="/privacy">Política de Privacidade</a>
                </div>
                <div class="copyright-bar">
                    2022 © Todos os direitos reservados <strong><router-link :to="{name:`${selectedLanguage}-gb`}">FMWhatsApp</router-link></strong>
                </div>
            </div>
        </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="gotodownload()">
        Baixar GBWhatsapp APK
    </div>

</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
    name: 'Home',
    components: {
        HeadNav,
    },
    data() {
        return {
            pageName: 'home',
            selectedLanguage: 'pt',
        };
    },
    mounted() {},
    methods: {
        gotodownload() {
            // const params = new URLSearchParams(window.location.href.search)
            let params = window.location.search;
            window.location.href = '/pt/downloadpage' + params;
        },
        gotoblog() {
            window.location.href = '/blogs';
        },
        jump(url) {
            window.location.href = url;
        },
        goto(page) {
            this.$router.push({
                name: `${this.selectedLanguage}-${page}`
            });
        }
    },
};
</script>
